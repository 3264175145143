import React, { useContext, useEffect, useRef, useState } from "react"
import { AccessoryCardProps } from "./AccessoryCard.d"
import tw from "twin.macro"
import { Image } from "../../atoms/Image"
import { AccessoryCardCarousel } from "."
import { SeriesAccessoriesContext } from "../../../contexts/SeriesAccessories"
import {
  generateInventoryLink,
  parseDisclaimerBlocks,
  stripDisclaimerCodes,
} from "../../../helpers"
import { toggleDisclaimersModal } from "../../../contexts/Disclaimers/actions"
import { DisclaimersContext } from "../../../contexts/Disclaimers"
import Icon from "../../atoms/Icon"
import { LanguageContext } from "../../../contexts/Language"
import { Link } from "../../atoms/Link"
import { ThreeArrowAnimation } from "../ThreeArrowAnimation"
import useTealiumEvent from "../../../hooks/Tealium/useTealiumEvent"
import { useTealiumContext } from "../../../contexts/Tealium"
import { css } from "@emotion/react"
import {
  addFavoritedAccessory,
  removeFavoritedAccessory,
  setFavoriteMessage,
} from "../../../contexts/Favorites/actions"
import { FavoritesContext } from "../../../contexts/Favorites/context"
import { firstLettersCapital } from "../../../helpers"
import { HeaderContext } from "../../../contexts/Header/context"
import { useStaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

/**
 *
 * Accessory Card
 *
 * @param {AccessoryCardProps} props
 * @param {string} props.title
 * @param {Object[]} props.media
 * @param {string} props.subCategory
 * @param {Object[]} props.modelsForAccy
 * @param {string} props.code
 * @param {string} props.description
 * @param {number} props.year
 *
 * @returns {JSXElement}
 */
const AccessoryCard: React.FC<AccessoryCardProps> = ({
  title,
  description,
  media,
  subCategory,
  modelsForAccy,
  code,
  partNumber,
  index,
  year,
  series,
  slug,
  type,
  fromPanel = false,
  ...remainingProps
}) => {
  const [{ filteredAccessories }] = useContext(SeriesAccessoriesContext)
  const [{ page }] = useContext(SeriesAccessoriesContext)
  const [{ setSelectedModelGroup }] = useContext(SeriesAccessoriesContext)
  const [{ selectedModelGroup }] = useContext(SeriesAccessoriesContext)
  const [{ models }] = useContext(SeriesAccessoriesContext)
  const { setOpen, viewedAcc, setViewedAcc } = useContext(HeaderContext)
  const [favoritesState, dispatch] = useContext(FavoritesContext)
  const [isFavorited, setIsFavorited] = useState(false)
  const [state, modalDispatch] = useContext(DisclaimersContext)
  const content = useRef(null)
  const [isExpanded, setisExpanded] = useState(false)
  const [descriptionValue, setDescriptionValue] = useState("")
  const { _ } = useContext(LanguageContext)

  let seriesSlug = slug || useContext(SeriesAccessoriesContext)[0]?.seriesSlug
  const getTruncatedDescription = () => {
    let truncatedText = description
    const maxLength = 85

    if (description?.startsWith("<ul><li>")) {
      const parser = new DOMParser()
      const doc = parser.parseFromString(description, "text/html")
      const liElements = doc.querySelectorAll("li")

      if (liElements.length > 2) {
        // Remove all <li> elements after the 2nd
        doc.querySelectorAll("li:nth-child(n+3)").forEach(el => el.remove())
        // Add ellipsis to the last <li> element
        doc.querySelector("li:nth-child(2)").innerHTML =
          liElements[1].innerHTML + "..."
      }

      // DOMParser puts the HTML fragment into the created document body
      const newHtml = doc?.body?.innerHTML
      truncatedText = newHtml?.toString()
    } else if (description?.length > maxLength) {
      truncatedText =
        description.substring(0, description.lastIndexOf(" ", maxLength)) +
        "..."
    }

    return truncatedText?.replaceAll('"', "")
  }

  useEffect(() => {
    if (viewedAcc?.code == code) {
      setisExpanded(true)
    }
  }, [viewedAcc])

  useEffect(() => {
    if (isExpanded) {
      setDescriptionValue(description?.replaceAll('"', ""))
    } else {
      setDescriptionValue(getTruncatedDescription())
    }
  }, [isExpanded, description])

  const customModels = useStaticQuery(graphql`
    query {
      customModels: allSanityCustomModel {
        nodes {
          accessoryCodes
          id
          grade
          year
          series {
            name
            slug
          }
        }
      }
    }
  `)?.customModels?.nodes

  const accyMediaSlides = media?.map((mediaItem: any) => ({
    image: mediaItem,
    alt: mediaItem?.alt,
  }))

  // this code block is responsible for iterating the ModelsForAccy and getting the model group title of the accys model
  let modelGroupTitle = []
  if (modelsForAccy && modelsForAccy.length > 0) {
    modelGroupTitle = modelsForAccy?.map((modelForAccy: any) => {
      const model = models?.find(models =>
        models.find(model => model.model.id === modelForAccy.id),
      )
      if (model) {
        return model.title ? model.title : model[0].model.name
      }
    })
  }

  // if the ModelsForAccy had models that were in the same group we need to remove those duplicates so we only display the group title once
  let distinctModelGroupTitle: any[] = []
  if (modelGroupTitle && modelGroupTitle.length > 0) {
    distinctModelGroupTitle = Array.from(
      new Set(modelGroupTitle?.map((x: any) => x)),
    )
  }
  // Check for previously favorite vehicles
  useEffect(() => {
    if (
      !favoritesState.favoritedAccessories ||
      !Array.isArray(favoritesState.favoritedAccessories)
    )
      return
    setIsFavorited(
      favoritesState.favoritedAccessories.filter(a => a.code === code).length >
        0,
    )
  }, [favoritesState.favoritedAccessories, filteredAccessories])
  // Tealium
  const { trackTealEvent } = useTealiumEvent()
  const getInventoryLink = (): string => {
    let params = {
      series: seriesSlug,
    }

    if (year) {
      params.year = [`${year}`]
    }

    // If partial accessory code, get accessory codes from associated custom model and send as accessoryModels param
    if (code?.startsWith("XX") && code?.length < 6) {
      const seriesCustomModels = Array.isArray(customModels)
        ? customModels
            ?.sort((a, b) => b?.year - a?.year)
            ?.filter(model => model?.series?.slug === seriesSlug)
        : []
      let codes = ""
      seriesCustomModels?.forEach(model => {
        const accessoryCodes = model?.accessoryCodes
          ?.split(",")
          ?.sort(function (a, b) {
            if (a < b) return -1
            if (a > b) return 1
            return 0
          })
        const includesCode = accessoryCodes?.find(code =>
          code?.startsWith(code),
        )
        if (includesCode) {
          codes = accessoryCodes?.join(",")
          return
        }
      })

      if (codes) {
        params.accessoryModels = [codes]
      }
    } else {
      params.accessory = [code]
    }
    const link = generateInventoryLink(params)
    return link
  }

  return (
    <div
      aria-label="Accessory Details"
      css={[
        tw`relative rounded-lg shadow-4 mb-12 content-between grid`,
        tw`relative flex flex-col flex-grow rounded-lg shadow-4 mb-12 sm:(shadow-4 mb-12)`,
        tw`lg:(mb-0)`,
      ]}
      id={`acc-${code}`}
    >
      <div>
        <div css={[tw`w-full h-auto bg-gray-100`]}>
          {accyMediaSlides?.length < 1 ? (
            <div
              css={[
                tw`flex items-center justify-center font-semibold h-[208px]`,
                tw`h-[256px]`,
              ]}
            >
              {_("Image Coming Soon")}
            </div>
          ) : accyMediaSlides?.length > 1 ? (
            <AccessoryCardCarousel
              slides={accyMediaSlides}
              title={title}
              analyticsId={index}
            />
          ) : (
            <Image
              imageData={accyMediaSlides[0]?.image}
              objectFit="cover"
              css={[tw`object-cover w-full`]}
            />
          )}
        </div>
        <div css={[tw`flex justify-between items-start px-4 pt-4 pb-2`]}>
          <div css={[tw`font-bold text-xl leading-[22px]`]}>
            {parseDisclaimerBlocks(title, selection =>
              modalDispatch(toggleDisclaimersModal(selection)),
            )}
          </div>
          <button
            onClick={() => {
              const newState = !isFavorited
              let favAccy = {
                code: code,
                partNumber: partNumber,
                title: title,
                description: description,
                subCategory: subCategory,
                year: year,
                media: media,
                modelsForAccy: modelsForAccy,
                type: type,
                series:
                  modelsForAccy && modelsForAccy[0]
                    ? modelsForAccy[0].series.name.toLowerCase()
                    : null,
                slug: seriesSlug,
              }
              if (newState === true) {
                dispatch(addFavoritedAccessory(favAccy))
                dispatch(setFavoriteMessage("accessories"))
              } else {
                dispatch(removeFavoritedAccessory(favAccy))
              }
              setIsFavorited(newState)
            }}
            aria-label={isFavorited ? `Unfavorite` : `Favorite`}
          >
            <Icon.Heart
              color={isFavorited ? "red-400" : "gray-900"}
              css={[
                tw`h-6`,
                css`
                  filter: drop-shadow(0 3px 3px rgb(10, 22, 70, 0.15))
                    drop-shadow(0 0 1px rgb(10, 22, 70, 0.06));
                `,
              ]}
              filled={isFavorited}
            />
          </button>
        </div>
        <div css={[tw`min-h-[45px]`]}>
          <div css={[tw`relative h-auto px-4 `]}>
            <div
              ref={content}
              css={[
                tw`transition-all mb-[.5rem] ease-out duration-500 opacity-100 h-10 text-sm`,
                isExpanded
                  ? `height: ${
                      content.current && content.current.scrollHeight
                    }px`
                  : tw`h-10 opacity-100`,
                tw`transition-all mb-[.5rem] ease-out duration-500 opacity-100 h-auto`,
              ]}
            >
              <div className="accCardDescription">
                {parseDisclaimerBlocks(
                  descriptionValue.replace(/\u0014/g, " "),
                  selection => modalDispatch(toggleDisclaimersModal(selection)),
                )}
              </div>

              {isExpanded && (
                <div css={[tw`pt-3 tracking-normal`]}>
                  {_("Accessory Code")}: {code}
                </div>
              )}
              {isExpanded && partNumber && (
                <div css={[tw`tracking-normal`]}>
                  {_("Part Numbers")}: {partNumber}
                </div>
              )}
            </div>
            <button
              type="button"
              css={[
                tw`flex items-center w-full`,
                // tw`lg:(mb-[4.5rem])`,
                "-webkit-tap-highlight-color: transparent;",
              ]}
              aria-expanded={isExpanded}
              aria-selected={isExpanded}
              onClick={() => setisExpanded(!isExpanded)}
            >
              <span css={[tw`font-sans`]}>
                {isExpanded ? _("View Less") : _("View More")}
              </span>
              <Icon.Chevron
                color="red-400"
                direction="down"
                css={[
                  tw`h-1.5 transition-all duration-300 mt-1 ml-2`,
                  isExpanded && tw`transform -rotate-180`,
                ]}
              />
            </button>
          </div>
        </div>
      </div>
      <div
        css={[
          tw`px-4 text-sm tracking-wider pb-8 mb-3 h-full`,
          tw`lg:(text-sm tracking-wider)`,
        ]}
      >
        <div css={[tw`my-2 font-bold not-italic`]}>
          {fromPanel ? _("Favorited On") : _("Available On")}
        </div>
        {fromPanel ? (
          <ul
            aria-label="Available on this Series"
            css={[tw`flex flex-wrap py-2`]}
          >
            <li
              css={[
                tw`bg-gray-200 text-gray-700 rounded-full flex items-center py-1 px-3 mr-2 mb-1 overflow-x-auto`,
              ]}
            >
              <p css={[tw`text-xs`]}>{firstLettersCapital(series)}</p>
            </li>
          </ul>
        ) : (
          <div>
            <ul
              aria-label="Available on these trim models"
              css={[tw`flex flex-wrap`]}
            >
              {distinctModelGroupTitle &&
                distinctModelGroupTitle.length > 0 &&
                distinctModelGroupTitle
                  .filter(title => title !== undefined)
                  .sort()
                  .map((title, i, arr) => (
                    <Link
                      onClick={() => {
                        const findModelGroup = models.find(
                          models => models.title === title,
                        )
                        setSelectedModelGroup(findModelGroup)
                        const access = document?.getElementById("accSection")
                        access.scrollIntoView()
                      }}
                      css={tw`py-0 cursor-pointer`}
                    >
                      <li
                        className="group"
                        css={[
                          tw`bg-gray-200 text-gray-700 hover:(bg-gray-600 text-white px-2) rounded-full flex items-center py-1 pl-3  pr-3.5 mr-2 mb-1 overflow-x-auto`,
                          selectedModelGroup?.title === title ||
                          selectedModelGroup?.name === title // handles case where model isn't in a group
                            ? tw`text-red-500`
                            : tw``,
                        ]}
                      >
                        <p css={[tw`text-xs`]}>{title}</p>
                        <Icon.Chevron
                          css={[
                            tw`w-2 ml-0.5 mt-0.5 hidden group-hover:(block)`,
                          ]}
                        />
                      </li>
                    </Link>
                  ))}
            </ul>
          </div>
        )}
        {fromPanel ? (
          <div css={[tw`h-full flex  content-end items-end`]}>
            <ul css={[tw`flex flex-wrap gap-2`]}>
              <li>
                <Link
                  animated
                  animatedThin
                  to={getInventoryLink()}
                  className="group"
                  css={tw`text-xs font-light`}
                  onClick={() => {
                    setOpen(null)
                  }}
                >
                  {_("View inventory")}{" "}
                  <Icon.Chevron
                    color="red-400"
                    css={[tw`w-2 ml-0.5 mb-0.5 inline-block `]}
                  />
                </Link>
              </li>
              <li>
                {" "}
                <Link
                  animated
                  animatedThin
                  to={`/accessories/${seriesSlug}`}
                  className="group"
                  css={tw`text-xs font-light`}
                  onClick={() => {
                    setViewedAcc({
                      fromPanel: true,
                      code: code,
                      type: type,
                    })
                    setOpen(null)
                  }}
                >
                  {_("View Details")}{" "}
                  <Icon.Chevron
                    color="red-400"
                    css={[tw`w-2 ml-0.5 mb-0.5 inline-block `]}
                  />
                </Link>
              </li>
            </ul>
          </div>
        ) : (
          <div css={[tw`absolute bottom-3 content-end items-end`]}>
            <Link
              animated
              animatedThin
              to={getInventoryLink()}
              className="group"
              css={tw`text-xs font-light`}
              onClick={() => {
                trackTealEvent({
                  tealium_event: "accessories_click",
                  vehicle_model: page?.vehicles[0]?.series.name || series,
                  vehicle_trim:
                    distinctModelGroupTitle &&
                    distinctModelGroupTitle.length > 0 &&
                    distinctModelGroupTitle.map(
                      (title: any) => title !== undefined && title,
                    ),
                  accessory_name: stripDisclaimerCodes(title),
                })
              }}
              analytics-id={`available inventory:accessories:${index + 1}`}
            >
              {_("Check available inventory")}{" "}
              <Icon.Chevron
                color="red-400"
                css={[tw`w-2 ml-0.5 mb-0.5 inline-block `]}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default AccessoryCard
